import Dexie, { Table } from "dexie";
import { jwtDecode } from "jwt-decode";

export interface Session {
  id?: number;
  userId: string;
  firstName?: string;
  lastName?: string;
  email: string;
  isEmailVerified: boolean;
  token: string;
  isCurrent: number;
}

export class LocalDB extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  sessions!: Table<Session>;

  constructor() {
    super("localDatabase");
    this.version(1).stores({
      sessions: "++id, token, userId, email, isCurrent", // Primary key and indexed props
    });
  }
}

type JwtPayload = {
  userId: string;
  firstName?: string;
  lastName?: string;
  email: string;
  isEmailVerified: boolean;
};

export const saveSession = async (authorization: string) => {
  if (authorization.split(" ")[0] !== "Bearer")
    throw new Error("Invalid authorization");

  const token = authorization.split(" ")[1];
  if (!token) throw new Error("Invalid authorization token");

  const decoded = jwtDecode<JwtPayload>(token);
  const currentSessions = await db.sessions
    .where("isCurrent")
    .equals(1)
    .toArray();

  const existingSession = await db.sessions.get({ userId: decoded.userId });
  if (existingSession?.id) {
    await db.sessions.bulkPut([
      ...currentSessions
        .filter((session) => session.id !== existingSession.id)
        .map((session) => ({ ...session, isCurrent: 0 })),
      { ...existingSession, token, isCurrent: 1 },
    ]);
  } else {
    await db.sessions.bulkPut(
      currentSessions.map((session) => ({ ...session, isCurrent: 0 }))
    );
    await db.sessions.add({ ...decoded, token, isCurrent: 1 });
  }
};

export const getCurrentSession = async () => {
  const currentSession = await db.sessions.get({ isCurrent: 1 });
  if (currentSession) return currentSession;
};

export const deleteCurrentSession = async () => {
  const currentSession = await getCurrentSession();
  if (currentSession?.id) await db.sessions.delete(currentSession.id);
};

export const changeUser = async () => {
  const currentSession = await getCurrentSession();
  if (currentSession?.id)
    await db.sessions.update(currentSession?.id, { isCurrent: 0 });
};

export const db = new LocalDB();

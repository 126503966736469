"use client";

import { useLiveQuery } from "dexie-react-hooks";
import { db, deleteCurrentSession } from "../db";
import Link from "next/link";

export const NavigationBar = () => {
	const session = useLiveQuery(() => db.sessions.get({ isCurrent: 1 }));

	return (
		<div className="flex items-center justify-between p-6 sticky top-0 bg-white border shadow-sm z-10">
			<Link href={session ? "/dashboard" : "/"}>
				<div className="text-2xl">Properteaz</div>
			</Link>
			<div>
				{session && (
					<>
						<Link href="/settings">
							<button>Settings</button>
						</Link>
						<button onClick={() => deleteCurrentSession()}>Sign out</button>
					</>
				)}
				{!session && (
					<Link href="/sign-in">
						<button className="link">Sign in</button>
					</Link>
				)}
				{!session && (
					<Link href="/sign-up">
						<button className="link">Create Account</button>
					</Link>
				)}
			</div>
		</div>
	);
};
